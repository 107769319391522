import { Box, Typography, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import hit from "../services/apiManager";
import { setLoading } from "../store/load";
import { pxToRem } from "../theme/typography";
import showToast from "../utils/showToast";
import { image_url } from "../services/endpoints";

export default function Quiz() {
    const { gId, postId } = useLocation().state
    const dispatch = useDispatch()
    const userMe = useSelector(state => state.auth?.user)
    const navigate = useNavigate()
    const [quizTitle, setQuizTitle] = useState("")
    const [quizDesc, setQuizDesc] = useState("")

    const [quizData, setQuizData] = useState([])
    const [data, setData] = useState([])

    useEffect(() => {
        getPostData()
    }, [])

    const updateQuizData = async (point, total, data) => {
        try {
            dispatch(setLoading(true))
            let res = await hit("/posts/quiz/" + postId, "patch", { point, datas: data, total, gId })
            if (!res.err) {
                showToast("Quiz submitted successfully!")
                navigate(-1)
            } else {
                showToast(res.msg, "error")
            }
        } catch (err) {

        } finally {
            dispatch(setLoading(false))
        }
    }

    const getPostData = async () => {
        try {
            dispatch(setLoading(true))
            let res = await hit("/posts/" + postId, "get")
            if (!res.err) {
                if (res?.data) {
                    setQuizData(res?.data?.quizData)
                    setQuizTitle(res?.data?.quizTitle)
                    setQuizDesc(res?.data?.quizDesc)
                    let quizedBy = res?.data?.quizedBy?.filter(x => x?.user == userMe?._id)
                    quizedBy.sort((a, b) => b?.lastUpdate - a?.lastUpdate)
                    if (quizedBy[0]) {
                        setData(quizedBy[0].datas?.map(x => {
                            let q = res?.data?.quizData?.find(d => d.qn == x?.qn)
                            return ({ ...x, point: 0 })
                        }))
                        let datas = quizedBy[0].datas
                        setQuizData(res?.data?.quizData?.map(x => {
                            let q = datas?.find(d => d.qn == x?.qn)
                            return ({
                                ...x, editable: q.ans != x?.ansKey

                            })
                        }))
                    }
                }
            } else {
                showToast(res.msg, "error")
            }
        } catch (err) {

        } finally {
            dispatch(setLoading(false))

        }
    }
    const REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

    const Paragraph = ({ paragraph }) => {
        // Define your regular expression pattern
        // Define your regular expression pattern
        // const REGEX = /https?:\/\/\S+/;

        if (!paragraph || paragraph.trim().length === 0) {
            return null;
        }

        const paragraphArray = paragraph.match(/\S+|\n/g);

        return (
            <div style={{ flex: 1, maxWidth: "400px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                {paragraphArray.map((word, index) => {
                    if (word.match(REGEX)) {
                        return (
                            <a key={index} href={word} className="text-blue-400">
                                {word}
                            </a>
                        );
                    } else {
                        return (
                            <span key={index}>
                                {word}{(word === '\n' || word === ' ')?'':" "}
                            </span>
                        );
                    }
                })}
            </div>
        );
    };
    return (
        <Box sx={{ px: "5%", pt: "20px", display: "flex", pr: "10%", overflowY: "auto", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: pxToRem(20), fontWeight: "600" }}>{quizTitle}</Typography>
                <Typography sx={{ fontSize: pxToRem(14), fontWeight: "400", textAlign: "center" }}> <Paragraph paragraph={quizDesc} /></Typography>
                {quizData?.map((x, i) => {
                    return (<Box sx={{ textAlign: "left" }}>
                        <Typography sx={{ fontSize: pxToRem(14), fontWeight: "500", marginTop: "20px" }}>Q{i + 1}. {x?.qn}</Typography>
                        <Box display={"flex"} marginTop={"10px"}>
                            {x?.photos?.map((x, id) => {
                                return <Box sx={{ position: "relative", marginRight: "10px" }}>
                                    <Box component={"img"} sx={{ height: "150px", width: "150px", borderRadius: "10px", border: "1px solid", borderColor: "rgb(16,176,212)", objectFit: "contain" }} src={typeof x === 'string' ? image_url + x : URL.createObjectURL(x)} />
                                </Box>
                            })}
                        </Box>
                        {x?.options?.map((op, ind) => {
                            let findQ = data?.find(d => d?.qn == x?.qn)
                            let isChecked = false
                            let point = x?.ansKey == op ? x?.point : 0
                            if (findQ?.ans == op) {
                                isChecked = true
                            }
                            return (
                                <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <Box onClick={() => {
                                        if (x?.editable == false) {
                                            return
                                        }
                                        let findQ = data?.find(d => d?.qn == x?.qn)
                                        if (findQ) {
                                            setData(data?.filter(d => d?.qn != x?.qn).concat({ qn: x?.qn, ans: op, point }))
                                        } else {
                                            setData(data.concat({ qn: x?.qn, ans: op, point }))
                                        }
                                    }} component={"img"} sx={{ cursor: "pointer", width: "18px", height: "18px" }} src={isChecked ? "assets/posts/check-circle-solid.svg" : "assets/posts/check-circle.svg"} />

                                    <Typography sx={{ marginLeft: "10px", fontSize: pxToRem(14), color: (x?.editable == false && op == x?.ansKey) ? "green" : "black" }}>{op}</Typography>
                                </Box>
                            )
                        })}
                    </Box>)
                })}
                <Button onClick={() => {
                    if (data?.length < quizData?.length) {
                        showToast("Please complete all questions", "warning")
                        return
                    }
                    let earned = data?.map(x => x?.point).reduce((a, b) => a + b, 0)
                    let total = quizData?.map(x => x?.point).reduce((a, b) => a + b, 0)
                    updateQuizData(earned, total, data?.map(x => ({ qn: x?.qn, ans: x?.ans })))
                }} sx={{ width: "15vw", height: "50px", mx: "auto" }} variant="contained" >
                    Submit
                </Button>
            </Box>
        </Box>
    )
}

