import React, { useEffect, useState } from 'react'
import moment from "moment"
import hit from "../../services/apiManager";
import showToast from "../../utils/showToast";
import TwoSelectionTab from "../../components/TwoSelectionTab";
import GroupScoreProfile from "../../components/ScoreBoard/GroupScoreProfile";
import ScoreButtons from './ScoreButtons';
import PointsComp from "../../components/ScoreBoard/PointsComp";
import { scores, teams } from "../../services/endpoints";
import { useSelector } from "react-redux"
import { Box, Typography, Button } from '@mui/material';
import { RankingHeader, RankingRowApi } from './Ranking';
import { pxToRem } from "../../theme/typography";

const PointHistory = ({ isAdmin, selectedTeam }) => {
    const quarter = [{
        title: "Daily", points: 0, show: 0
    }, {
        title: "Weekly", points: 0, show: 0
    }, {
        title: "Monthly", points: 0, show: 0
    }, {
        title: "Miscellaneous", points: 0, show: 1
    }]

    const user = useSelector(state => state?.auth?.user);
    const rankingPageSize = 50;
    
    const [currentTab, setCurrentTab] = useState(0)
    const [selectedQuarter, setSelectedQuarter] = useState("")
    const [ableToScore, setAbleToScore] = useState(false)
    const [allScores, setAllScores] = useState([])
    const [adminView, setAdminView] = useState("Admin")
    const [sdate, setSdate] = useState('')
    const [edate, setEdate] = useState('')
    const [mode, setMode] = useState('all'); //all daily weekly monthly miscellaneous
    const [rankings, setRankings] = useState([]);
    const [rankingPage, setRankingPage] = useState(1);
    const [totalRankPage, setTotalRankPage] = useState(0);
    // points
    const pointsPageSize = 100;
    const [pointsPage, setPointsPage] = useState(1);
    const [totalPointsPage, setTotalPointsPage] = useState(0);

    // scores
    const [scoreList, setScoreList] = useState([]);

    const getRankings = async (group, page = 1) => {
        if (group?._id) {
            if (page == 1) { //incase  somehow 2 times fn called then delete prev one
                setRankings([]);
            }
            let query = `?groups=${group?._id}&mode=${mode}&page=${page}&pageSize=${rankingPageSize}`;
            if (mode == 'miscellaneous') {
                query = query.concat(
                    `&startDate=${moment(sdate).format('DD-MM-YYYY')}&endDate=${moment(
                        edate,
                    ).format('DD-MM-YYYY')}`,
                );
            }
            let res = await hit(`${scores?.ranking}${query}`);
            if (!res?.err) {
                let rankingData = res?.data?.ranking;
                rankingData = Array.isArray(rankingData) ? rankingData : [];
                let prevRanking = []
                if(page != 1) {
                    prevRanking = [...rankings];
                }
                prevRanking = [...prevRanking, ...rankingData];
                setRankings(prevRanking);
                setRankingPage(page);
                setTotalRankPage(res?.data?.totalPages);
            } else {
                showToast(res?.msg || 'Something went wrong.');
            }
        }
    }

    const getPointsData = async (id, newpage = null) => {
        let query = `?groups=${id}&mode=${mode}&page=${newpage || pointsPage}&pageSize=${pointsPageSize}&user=${user?._id}`;
        if (mode == 'miscellaneous') {
            query = query.concat(
                `&startDate=${moment(sdate).format('DD-MM-YYYY')}
                &endDate=${moment(edate).format('DD-MM-YYYY')}`,
            );
        }
        let res = await hit(`${scores?.getscore}/average${query}`);
        if (res?.err) {
            showToast(res?.msg)
        } else {
            setAllScores(res?.data)
            if (newpage && newpage != 1) {
                setScoreList([...scoreList, ...res?.data?.list])
            } else {
                setScoreList(res?.data?.list)
            }
            setTotalPointsPage(res?.data?.totalPages ?? 1)
        }
    }

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        return tmp;
    }

    const saveMisDate = async (startDate, endDate) => {
        setSdate(startDate)
        setEdate(endDate)
        setScoreList([]);
        let mode = "all"
        if (startDate == '' && endDate == '') {
            mode = "all"
        } else if (startDate != '' && endDate != '') {
            mode = "miscellaneous"
        }
        setMode(mode)
        let query = `?groups=${selectedTeam?._id}&mode=${mode}&page=1&pageSize=${pointsPageSize}&user=${user?._id}`;
        if (mode == 'miscellaneous') {
            query = query.concat(
                `&startDate=${moment(startDate).format('DD-MM-YYYY')}
                &endDate=${moment(endDate).format('DD-MM-YYYY')}`,
            );
        }
        let res = await hit(`${scores?.getscore}/average${query}`);
        if (res?.err) {
            showToast(res?.msg)
        } else {
            setAllScores(res?.data)
            setScoreList(res?.data?.list)
            setTotalPointsPage(res?.data?.totalPages ?? 1)
        }
        // Ranking Data
        setRankings([]);
        let rQuery = `?groups=${selectedTeam?._id}&mode=${mode}&page=1&pageSize=${rankingPageSize}`;
        if (mode == 'miscellaneous') {
            rQuery = rQuery.concat(
                `&startDate=${moment(startDate).format('DD-MM-YYYY')}
                &endDate=${moment(endDate).format('DD-MM-YYYY')}`,
            );
        }
        let rankingRes = await hit(`${scores?.ranking}${rQuery}`);
        if (!rankingRes?.err) {
            let rankingData = res?.data?.ranking;
            setRankings(rankingData);
            setRankingPage(1);
            setTotalRankPage(res?.data?.totalPages);
        } else {
            showToast(res?.msg || 'Something went wrong.');
        }
    }

    useEffect(() => {
        if (selectedTeam) {
            setRankings([])
            setScoreList([])
            getPointsData(selectedTeam?._id, 0)
            getRankings(selectedTeam, 1)
            if (isAdmin) {
                if (selectedTeam?.ableToScores.includes(user?._id)) {
                    setAbleToScore(true)
                } else {
                    setAbleToScore(false)
                }
            } else {
                setAbleToScore(false)
            }
        }
    }, [selectedTeam])

    return (
        <>
            {ableToScore &&
                <TwoSelectionTab
                    setCurrentTab={(v) => {
                        if (v == 0) {
                            setAdminView("Admin")
                        } else {
                            setAdminView("User")
                        }
                    }}
                    t1={"Admin"}
                    t2="User"
                    style={{ background: "white", marginTop: "0px", marginBottom: "25px" }}
                />
            }
            {
                [0].map((x) => {
                    let totalTemp = 0
                    return (
                        <GroupScoreProfile
                            isScore={true}
                            fin={isAdmin ? adminView == "User" ? selectedTeam?.myTotalPoints ?? 0 : selectedTeam?.avg_score ?? 0 : selectedTeam?.myTotalPoints ?? 0}
                            adminView={adminView}
                            totalTemp={totalTemp}
                            isAdmin={isAdmin}
                            avgPoint={selectedTeam?.avg_score ?? 0}
                            yourPoint={selectedTeam?.myTotalPoints ?? 0}
                            team={selectedTeam}
                        />
                    )
                })
            }
            <br />
            <Typography sx={{ fontWeight: "600", textAlign: "left" }}>Groups</Typography>
            <br />
            {splitArray(quarter, 2).map(items => {
                return (
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                        {items?.map((item, index) => {
                            let point = 0
                            if (item?.title == "Daily") {
                                point = allScores?.sum?.todaySumofPoints ?? 0
                            } else if (item?.title == "Weekly") {
                                point = allScores?.sum?.weeklySumofPoints ?? 0
                            } else if (item?.title == "Monthly") {
                                point = allScores?.sum?.monthlySumofPoints ?? 0
                            } else {
                                if (sdate && edate) {
                                    point = allScores?.sum?.totalSumofPoints ?? 0
                                }
                            }
                            return (
                                <>
                                    <ScoreButtons
                                        setSelectedQuarter={setSelectedQuarter}
                                        styleView={{ marginLeft: index != 0 ? "4%" : "0%" }}
                                        groupDetails={selectedTeam}
                                        isAdmin={isAdmin}
                                        item={item}
                                        index={index}
                                        selected={selectedQuarter}
                                        point={point}
                                        onPress={() => {
                                            setSelectedQuarter(item?.title)
                                        }}
                                        onDateSelect={(startDate, endDate) => {
                                            saveMisDate(startDate, endDate)
                                        }}
                                    />
                                </>
                            )
                        })}
                    </Box>
                )
            })}
            <br />
            <TwoSelectionTab
                setCurrentTab={setCurrentTab}
                t1={"Points"}
                t2="Ranking"
                style={{ background: "white" }}
            />
            <br />
            {currentTab == 0 &&
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    {scoreList?.map((i, j) => {
                        return <PointsComp i={i} j={j} style={{ marginTop: j != 0 ? "15px" : "0px" }} admin={isAdmin} key={i?._id} adminView={adminView} />
                    })
                    }
                    {totalPointsPage > pointsPage && <Button onClick={() => {
                        setPointsPage(pointsPage+1)
                        getPointsData(selectedTeam?._id, pointsPage+1)
                    }} sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                        Load More
                    </Button>}
                </Box>
            }
            {currentTab == 1 &&
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <RankingHeader isAdmin={isAdmin} />
                    {rankings && rankings.map((i, j) => {
                        return (
                            <RankingRowApi adminView={adminView} points={[]} isAdmin={isAdmin} i={i} j={j} key={i?._id} admin={isAdmin} user={user?._id} currentGrp={selectedTeam}></RankingRowApi>
                        )
                    })
                    }
                    {isAdmin && 
                        <Typography 
                            sx={{ fontSize: pxToRem(14), marginTop: "10px", fontWeight: "500" }}>
                                Note:- A for Admin and S for Scoring enabled.
                        </Typography>}
                        {totalRankPage > rankingPage && 
                        <Button 
                            onClick={() => { getRankings(selectedTeam, rankingPage + 1)}} 
                            sx={{ marginTop: "20px", color: "#58C0DC" }} 
                            variant="outline">
                        Load More
                    </Button>}
                </Box>
            }
            <br />
        </>
    )
}

export default PointHistory