import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom"
import { teams } from "../../services/endpoints";
import { setLoading } from "../../store/load";
import { getAllTeamThunk, setSelectedTeam } from "../../store/team";
import SearchBar from "../../components/Home/SearchBar";
import ScoreCard from "../../components/ScoreBoard/ScoreCard";
import hit from "../../services/apiManager";
import showToast from "../../utils/showToast";
import _ from 'lodash'
import PointHistory from "./PointHistory"

export default function ScoreBoard() {
    
    const location = useLocation()
    const dispatch = useDispatch()

    const groups = useSelector(state => state?.team?.team)
    const selectedTeam = useSelector(state => state?.team?.selectedTeam)
    const userDetails = useSelector(state => state?.auth?.user)

    const [search, setSearch] = useState("")
    const [allGroup, setAllGroup] = useState([])
    const [groupDetail, setGroupDetail] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)

    const searchGroup = (key) => {
        let arr = groups
        let temp = arr.filter(x => String(x?.name)?.trim().toLowerCase().includes(key?.trim().toLowerCase()))
        setTimeout(() => {
            setAllGroup(temp)
        }, 400);
    }

    function splitArray(array, part) {
        var tmp = [];
        for (var i = 0; i < array.length; i += part) {
            tmp.push(array.slice(i, i + part));
        }
        return tmp;
    }

    const getGroupDetail = async () => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${teams?.createTeam}/${selectedTeam?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg, "error")
            } else {
                setGroupDetail(res?.data)
            }
        } catch (e) {} 
        finally {
            dispatch(setLoading(false))
        }
    }

    useEffect(() => {
        if (selectedTeam != null) {
            getGroupDetail()
        }
    }, [selectedTeam, location])

    useEffect(() => {
        if (search?.length == 0) {
            setAllGroup(groups)
        }
    }, [search])

    useEffect(() => {
        if (groupDetail && userDetails) {
            let admins = groupDetail?.admins?.map((x) => x?._id)
            if (admins?.includes(userDetails?._id)) {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
        }
    }, [groupDetail, userDetails])

    useEffect(() => {
        if (selectedTeam == null) {
            dispatch(setSelectedTeam(groups[0]))
        }
    }, [selectedTeam, groups])

    useEffect(() => {
        setAllGroup(groups)
    }, [groups])

    useEffect(() => {
        dispatch(getAllTeamThunk())
    }, [location])

    return (
        <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "8%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
            <Box sx={{ flex: 1, minHeight: "100px" }}>
                <SearchBar 
                    onClickSearch={() => { searchGroup(search)}} 
                    value={search} 
                    onChange={v => setSearch(v)} 
                    placeholder={"Search...."} 
                />
                <br />
                <Box sx={{ borderRadius: "5px", px: "10px", py: "20px", background: "white", boxShadow: "0px 5px 20px #26262626", minHeight: "100px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "600" }}>Groups</Typography>
                    </Box>
                    <br />
                    {splitArray(allGroup, 2)?.map((items) => {
                        return (
                            <Box 
                                sx={{ display: "flex", marginBottom: "15px", justifyContent: "space-between", alignItems: "center" }}>
                                    {
                                        items?.map((item, index1) => 
                                        <ScoreCard 
                                            isTeam={false} 
                                            item={item} 
                                            onSelect={() => { dispatch(setSelectedTeam(item))}} 
                                            style={{ marginLeft: index1 != 0 ? "20px" : "0px" }} 
                                            title={item?.name} 
                                        />)
                                    }
                            </Box>
                        )
                    })}
                </Box>
                <Box sx={{ height: "20px" }} />
            </Box>
            <Box sx={{ flex: 1, marginLeft: "4%" }}>
                {selectedTeam != null && <PointHistory isAdmin={isAdmin} selectedTeam={selectedTeam} />}
            </Box>
        </Box>
    )
}