import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import { setLoading } from "../../store/load";
import { toast } from 'react-toastify';
import hit from "../../services/apiManager";

const ScoreButtons = (props) => {
    let { item, groupDetails, isAdmin, adminView, styleView, selected, onDateSelect, onPress, point, onPressDone } = props
    let isSelected = selected == item?.title
    const [localGroupDetails, setLocalGroupDetails] = useState('')
    const [localStartDate, setLocalStartDate] = useState('')
    const [localEndDate, setLocalEndDate] = useState('')
    const dispatch = useDispatch()
    const theme = useTheme()

    useEffect(() => {
        setLocalGroupDetails(groupDetails)
    }, [groupDetails])

    const updateGroupDetailsData = async (value) => {
        dispatch(setLoading(true))
        let res = await hit("teams/" + localGroupDetails?._id, "patch", { showMiscBoard: value })
        if (!res.err) {
            setLocalGroupDetails({
                ...localGroupDetails,
                showMiscBoard: value
            })
        } else {
            toast.error("something went wrong while switching value")
        }
        dispatch(setLoading(false))
    }

    return (
        <Box
            onClick={onPress}
            sx={[{ boxShadow: "0px 5px 20px #26262626", height: "80px", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", px: "10px", py: "10px", borderRadius: "5px", flex: 1, background: (isSelected) ? theme.palette.primary.main : "white" }, styleView]}>
            <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "600", color: (isSelected && !onPressDone) ? ((onPressDone && item?.show == 0) ? theme.palette.primary.main : "white") : theme.palette.text, }}>{item?.title}</Typography>
                {(item?.show && isAdmin) ? 
                    <Box sx={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => { updateGroupDetailsData(!localGroupDetails?.showMiscBoard) }} component={"img"} src={localGroupDetails?.showMiscBoard ? "assets/notis/on.svg" : "assets/notis/off.svg"} />
                    : null
                }
            </Box>
            <Box
                onClick={(e) => e.stopPropagation()}
                display={"flex"}
                alignItems={"center"}>
                <Box sx={{ display: "flex", background: "white", border: `1px solid ${theme.palette.primary.main}`, marginRight: "5px", justifyContent: "center", alignItems: "center", padding: "4px", borderRadius: "4px", }}>
                    <Typography sx={{ color: theme.palette.primary.main, fontSize: "14px", fontWeight: "600" }}>{Math.round(point)}{!isAdmin ? `` : adminView == "User" ? `` : ""}</Typography>
                </Box>
                {item?.show && (isAdmin || (!isAdmin && localGroupDetails?.showMiscBoard)) ?
                    <DateRangePicker
                        placeholder="Select Date Range"
                        format="MMM, dd, yyyy"
                        style={{ color: theme.palette.primary.main }}
                        value={[localStartDate != "" ? new Date(localStartDate) : '', localEndDate != "" ? new Date(localEndDate) : '']}
                        placement="auto"
                        onChange={(v) => {
                            if (v && v?.length > 0) {
                                if (v?.length == 1) {
                                    setLocalStartDate(v[0])
                                    setLocalEndDate(v[0])
                                    onDateSelect(v[0], v[0])
                                } else if (v.length == 2) {
                                    setLocalStartDate(v[0])
                                    setLocalEndDate(v[1])
                                    onDateSelect(v[0], v[1])
                                }
                            } else {
                                setLocalStartDate('')
                                setLocalEndDate('')
                                onDateSelect('', '')
                            }
                        }}
                    />
                    : null
                }
            </Box>
        </Box>
    )
}
export default ScoreButtons